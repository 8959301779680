import React, { useState, useEffect, useRef, useContext } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { StateContext } from './components/StateContainer';
import StoricoConversazioni from './components/StoricoConversazioni';
import ChatbotConStorico from './components/ChatbotConStorico';

const KnotsChat = ({ content }) => {
  const { id } = useParams();
  let iframeCode = `${process.env.REACT_APP_CHATBOT}?projectCode=${id}`;

  let iframe = document.getElementById('iframe-chatbot');

  const {
    allProjectInformation,
    setAllProjectInformation,
    setLoading,
    setError,
    token,
    conversationCode,
    aggiornamentoDatiProgetto,
    titoloProgetto,
    setTitoloProgetto,
    temperatura,
    setTemperatura,
    istruzioniChatbot,
    setIstruzioniChatbot,
    primoMessaggioBot,
    setPrimoMessaggioBot,
    coloreMessaggi,
    setColoreMessaggi,
    allineamentoBottoneChatbot,
    setAllineamentoBottoneChatbot,
    windowWidth,
    setPageTitle,
  } = useContext(StateContext);

  useEffect(() => {
    setPageTitle('Test Assistant');
  }, []);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: {
        projectCode: id,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('tokenExpiration');
          window.location.replace('/login');
        } else {
          setTitoloProgetto(response.data.response.title);
          setTemperatura(response.data.response.temperature);
          setIstruzioniChatbot(response.data.response.basePrompt);
          setPrimoMessaggioBot(response.data.response.initialMessage);
          setColoreMessaggi(response.data.response.userMessageColor);
          setAllineamentoBottoneChatbot(
            response.data.response.chatBubbleAlignement
          );

          setAllProjectInformation(response.data.response);
        }
      })
      .catch((err) => setError(err));
  }, [aggiornamentoDatiProgetto]);

  useEffect(() => {
    function resizeIframe() {
      const iframe = document.getElementById('iframe-chatbot');
      let rowIframeTestAssistant = document.getElementById(
        'row-iframe-test-assistant'
      );
      if (iframe) {
        if (windowWidth < 992) {
          const windowHeight = window.innerHeight;
          iframe.style.height = `${windowHeight - 150}px`;
          rowIframeTestAssistant.style.height = '100%';
        } else {
          iframe.style.height = `100%`;
          rowIframeTestAssistant.style.height = '800px';
        }
      }
    }

    // Chiama la funzione di ridimensionamento iniziale e ogni volta che la finestra viene ridimensionata.
    resizeIframe();
    window.addEventListener('resize', resizeIframe);

    // Rimuovi l'ascoltatore dell'evento "resize" quando il componente viene smontato.
    return () => {
      window.removeEventListener('resize', resizeIframe);
    };
  }, [windowWidth]);

  return (
    <div
      className="row"
      id="row-iframe-test-assistant"
      style={{ height: '750px' }}
    >
      <div className="col-12">
        <div className="iframe-container h-100" style={{ minHeight: '800px' }}>
          <iframe
            src={iframeCode}
            id="iframe-chatbot"
            className="h-100 p-0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default KnotsChat;
