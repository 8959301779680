import React, { useContext, useState } from "react";
import Moment from "react-moment";
import "moment/locale/it";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { StateContext } from "../StateContainer";
import axios from "axios";

const ModalEliminaProgetto = ({ projectCode, projectTitle }) => {
  const {
    loading,
    setLoading,
    error,
    setError,
    token,
    listOfProjects,
    setListOfProjects,
    nuovoProgetto,
    setPageTitle,
    setEliminaProgetto,
    setShowDeleteModal,
  } = useContext(StateContext);

  const closeButton = document.getElementById("close-button");
  const [isDeleting, setIsDeleting] = useState(false);
  const modalElimina = document.getElementById("sa-warning");
  const body = document.getElementById("bodyApp");
  const EliminaProgetto = (code) => {
    setEliminaProgetto([]);
    setLoading(true);
    setIsDeleting(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/delete_project`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        projectCode: code, // This is the body part
        token: token,
      },
    })
      .then((response) => {
        setLoading(false);
        setEliminaProgetto(response);

        // Simulate button click
        const closeButton = document.getElementById("close-button");
        if (closeButton) {
          closeButton.click();
        }
        setIsDeleting(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          //nothing
        }
      });
  };

  return (
    <div
      className='modal fade overlay'
      id='sa-warning'
      tabIndex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='false'
      data-bs-backdrop='false'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header border-0 py-4 d-flex align-items-center'>
            <div className='modal-title delete'>
              <h4 className='m-0'>Elimina progetto</h4>
            </div>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              id='close-button'
              aria-label='Close'
            ></button>
          </div>
          <div className='modal-body'>
            {isDeleting ? (
              <div className='form-outline'>
                <div className='loading-dots'>
                  <div className='loading-dots--dot'></div>
                  <div className='loading-dots--dot'></div>
                  <div className='loading-dots--dot'></div>
                </div>
              </div>
            ) : (
              <h3>
                Sei sicuro di voler eliminare il progetto "{projectTitle}"
              </h3>
            )}
          </div>
          <div className='modal-footer border-0'>
            <button
              type='button'
              className={`btn btn-primary`}
              data-bs-dismiss='modal'
            >
              Annulla
            </button>
            <button
              type='button'
              className={`btn btn-danger text-white`}
              onClick={() => EliminaProgetto(projectCode)}
              id='creaProgettoButton'
            >
              Elimina Progetto
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEliminaProgetto;
