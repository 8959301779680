import React, { useContext } from 'react';
import Moment from 'react-moment';
import 'moment/locale/it';
import { FiEdit2 as Matita } from 'react-icons/fi';
import { CgTrash as Cestino } from 'react-icons/cg';
import { Link, useLocation } from 'react-router-dom';
import { StateContext } from './StateContainer';
import ModalEliminaProgetto from './Modals/ModalCreaProgetto';
import ModalCreaProgetto from './Modals/ModalCreaProgetto';

const RigaProgetto = ({ progetto }) => {
  const {
    loading,
    setLoading,
    error,
    setError,
    token,
    listOfProjects,
    setListOfProjects,
    nuovoProgetto,
    setPageTitle,
    setEliminaProgetto,
    setCodiceProgetto,
    setProjectTitle,
    showDeleteModal,
    setShowDeleteModal,
  } = useContext(StateContext);

  const modalElimina = document.getElementById('sa-warning');
  const bodyElimina = document.getElementById('bodyApp');

  const MostraModalElimina = (codice, title) => {
    setShowDeleteModal(true);
    setCodiceProgetto(codice);
    setProjectTitle(title);
  };

  return (
    <React.Fragment key={progetto.code}>
      <tr>
        <React.Fragment>
          <td className="bg-white align-items-center align-middle">
            <div className="py-2">{progetto.title || 'Non definito'}</div>
          </td>

          <td className="bg-white align-middle">
            {(progetto.charsCount || 0).toLocaleString()}
          </td>
          <td className="bg-white align-middle">
            {progetto.type === 'qa-agent'
              ? 'Assistente Q&A'
              : progetto.type === 'conversational'
              ? 'Conversazionale'
              : progetto.type === 'mysql-agent'
              ? 'Agente MySQL'
              : 'Agente E-mail'}
          </td>

          <td className="bg-white align-middle">
            <Moment format="DD MMMM YYYY" locale="it">
              {progetto.created.date}
            </Moment>
          </td>
          <td className="bg-white align-middle">
            <div className="">
              <Link
                to={`/progetti/modifica-progetto/${progetto.code}`}
                className="btn bg-green-second text-white px-2 py-1"
              >
                <Matita />
              </Link>
              <button
                className="btn btn-danger ms-2 px-2 py-1 waves-effect waves-light"
                role="button"
                type="button"
                title="Elimina Progetto"
                data-bs-toggle="modal"
                data-bs-target="#sa-warning"
                onClick={() =>
                  MostraModalElimina(progetto.code, progetto.title)
                }
              >
                <Cestino />
              </button>
            </div>
          </td>
        </React.Fragment>
      </tr>
    </React.Fragment>
  );
};

export default RigaProgetto;
