import React, { useContext, useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import { Link } from "react-router-dom";
import ImgLogo from "./img/Fulgeas_logo.png";
import ImgProfile from "./img/avatar-1.jpg";
import axios from "axios";
import { StateContext } from "./components/StateContainer";
import { HiUser as IconaUserSenzaImmagine } from "react-icons/hi2";
import Loading from "./components/Loading";

const Profile = () => {
  const {
    setPageTitle,
    AllUserData,
    userAvatar,
    token,
    userProfileData,
    setUserProfileData,
    profileLoading,
    setProfileLoading,
  } = useContext(StateContext);
  useEffect(() => {
    setPageTitle("Profilo");
  }, []);

  useEffect(() => {
    setProfileLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/user/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setProfileLoading(false);
        setUserProfileData([response.data.response.user]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  return (
    <div className='App'>
      <div id='layout-wrapper'>
        <Navbar />
        <Sidebar />
        <div className='main-content'>
          <div className='page-content-profile'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-flex align-items-center justify-content-between'>
                    <h4 className='mb-0'>Il tuo profilo</h4>

                    <div className='page-title-right'>
                      <ol className='breadcrumb m-0'>
                        <li className='breadcrumb-item'>
                          <Link
                            to='/dashboard'
                            className='text-decoration-none text-secondary'
                          >
                            Home
                          </Link>
                          {""} / Profile
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row position-relative pb-5'>
                <div className='col-12 h-auto'>
                  <div className='row justify-content-center h-auto'>
                    <div className='col-12'>
                      <div className='row h-auto'>
                        <div className='col-auto w-100 h-auto'>
                          <div className='card profile-box text-center m-0 h-auto'>
                            <div className='card-body p-4 h-auto d-grid d-lg-flex justify-content-lg-between justify-content-lg-center align-items-center'>
                              <div className='d-lg-flex d-grid justify-content-lg-start justify-content-center align-items-center gap-3'>
                                <div className='w-100 d-flex justify-content-center'>
                                  <div
                                    className='profile-info-page m-0 d-flex justify-content-center  fs-1'
                                    style={{
                                      background: `${
                                        userAvatar === null
                                          ? "gainsboro"
                                          : `url:("${userAvatar}")`
                                      }`,
                                    }}
                                  >
                                    {userAvatar === null ? (
                                      <IconaUserSenzaImmagine />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className='text-center text-lg-start'>
                                  <p className='fw-bold m-0'>
                                    {AllUserData.name} {AllUserData.lastname}
                                  </p>
                                  <p className='m-0 font-size-12'>
                                    {AllUserData.email}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <p className='m-0 d-block d-lg-none my-3 text-muted font-size-12'>
                                  Piano: <b>{AllUserData.plan.name}</b>
                                </p>

                                <p className='fw-bold m-0 d-none d-lg-block font-size-15'>
                                  {AllUserData.plan.name}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Crediti rimasti e Caratteri Totali */}

                          <div className='row'>
                            <div className='col-12 col-lg-6 mt-3'>
                              <div className='card'>
                                <div className='card-header text-uppercase bg-transparent border-0 fs-6'>
                                  Caratteri Totali
                                </div>
                                <div className='card-body fs-1'>
                                  {profileLoading ? (
                                    <Loading />
                                  ) : userProfileData[0] ? (
                                    userProfileData[0].charsCount.toLocaleString()
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 col-lg-6 mt-3'>
                              <div className='card'>
                                <div className='card-header text-uppercase bg-transparent border-0 fs-6'>
                                  Crediti Rimasti
                                </div>
                                <div className='card-body fs-1'>
                                  {profileLoading ? (
                                    <Loading />
                                  ) : userProfileData[0] ? (
                                    userProfileData[0].creditsLeft.toLocaleString()
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Progetti totali e Massime domande */}

                          <div className='row'>
                            <div className='col-12 col-lg-6 mt-3'>
                              <div className='card'>
                                <div className='card-header text-uppercase bg-transparent border-0 fs-6'>
                                  Richieste massime
                                </div>
                                <div className='card-body fs-1'>
                                  {profileLoading ? (
                                    <Loading />
                                  ) : userProfileData[0] ? (
                                    userProfileData[0].plan.maxQuery.toLocaleString()
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='col-12 col-lg-6 mt-3'>
                              <div className='card'>
                                <div className='card-header text-uppercase bg-transparent border-0 fs-6'>
                                  Progetti massimi
                                </div>
                                <div className='card-body fs-1'>
                                  {profileLoading ? (
                                    <Loading />
                                  ) : userProfileData[0] ? (
                                    userProfileData[0].plan.maxProjects.toLocaleString()
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
