import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "./StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";

const ComportamentoAssistente = ({ progetto }) => {
  const { id } = useParams();
  const {
    setLoading,
    setError,
    token,
    setAggiornamentoDatiProgetto,
    titoloProgetto,
    setTitoloProgetto,
    temperatura,
    setTemperatura,
    istruzioniChatbot,
    setIstruzioniChatbot,
    topK,
    setTopK,
    notifyTo,
    setQueries,
    setNotifyTo,
    collectCustomerEmail,
    setCollectCustomerEmail,
    chatbotIcon,
    setChatbotIcon,

    // Collect Lead Fields
    collectLeadName,
    collectLeadEmail,

    collectLeadCompany,
    collectLeadJobTitle,

    collectLeadPhone,
    collectLeadMessage,

    collectLeadWhen,
    allowSkipContact,
    messageHistoryLimit,
    setMessageHistoryLimit,

    // Domande Predefinite
    queries,
  } = useContext(StateContext);

  useEffect(() => {
    if (progetto[0]) {
      setTitoloProgetto(progetto[0].title);
      setTemperatura(progetto[0].temperature || 0);
      setIstruzioniChatbot(progetto[0].basePrompt);
      setTopK(progetto[0].analysisDepth || 0);
      setMessageHistoryLimit(progetto[0].messageHistoryLimit);
      setNotifyTo(progetto[0].notifyTo || "");
    } else {
      //Nothing
    }
  }, [progetto]);

  const aumentaTemperatura = () => {
    const nuovaTemperatura = parseFloat(temperatura) + 0.1;
    setTemperatura(nuovaTemperatura.toFixed(1));
  };

  const diminuisciTemperatura = () => {
    const nuovaTemperatura = parseFloat(temperatura) - 0.1;
    setTemperatura(nuovaTemperatura.toFixed(1));
  };

  const isTemperaturaMinima = parseFloat(temperatura) <= 0;
  const isTemperaturaMassima = parseFloat(temperatura) >= 2;

  const AggiornaProgetto = (event) => {
    UploadChatbotIcon();
    const overlayDataModal = document.getElementById("overlayDataModal");

    event.preventDefault();
    // let allPredefinedQueries = "";
    // queries.forEach((query) => {
    //   return (allPredefinedQueries +=
    //     '["' + `${query.label}||` + `${query.question}` + '"]');
    // });

    let allPredefinedQueries = [];
    queries.forEach((query) => {
      return allPredefinedQueries.push(`"${query.label}||${query.question}"`);
    });

    // '["Label1||Question1", "Label2||Question2"]'

    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        title: titoloProgetto,
        basePrompt: istruzioniChatbot,
        temperature: temperatura,
        domains: "",
        maxMessageNumber: "",
        maxMessageNumberPeriod: "",
        rateLimitMessage: "",
        analysisDepth: topK,
        messageHistoryLimit: messageHistoryLimit,
        notifyTo: notifyTo,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (response.status === 200) {
          setLoading(false);
          setAggiornamentoDatiProgetto(response.status);
        }
      })
      .catch((err) => setError(err));
  };

  const RangeTopK = (event) => {
    const value = parseInt(event.target.value);
    setTopK(value);
  };

  const RangeStoricoConversazioni = (event) => {
    const value = parseInt(event.target.value);
    setMessageHistoryLimit(value);
  };

  const [contentData, setContentData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const maxSize = 1024 * 1024; // 1 mega

  const UploadChatbotIcon = () => {
    let file = chatbotIcon;
    setChatbotIcon("");

    if (file) {
      const targetWidth = 128;
      const targetHeight = 128;

      // Creare un elemento immagine per caricare il file
      const img = new Image();
      img.src = URL.createObjectURL(file[0]);

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        // Crea un canvas con le dimensioni target
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calcola il rapporto di ridimensionamento
        const scaleX = targetWidth / width;
        const scaleY = targetHeight / height;

        // Disegna l'immagine ridimensionata sul canvas con le dimensioni target
        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        // Converti il canvas in un Blob
        canvas.toBlob((blob) => {
          // Il file è stato ridotto con successo
          const compressedFile = new File([blob], file[0].name, {
            type: file[0].type,
          });
          proceedWithUpload([compressedFile]);
        }, file[0].type);
      };
    } else {
      return;
    }
  };

  const proceedWithUpload = (file) => {
    const cancelFile = axios.CancelToken.source();
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      {
        file: file[0],
        progress: 0,
        status: "uploading",
        cancelToken: cancelFile,
      },
    ]);
    setContentData((currentScene) => [...currentScene, file[0]]);

    const requestId = `upload_${file[0].name}`;
    setCurrentRequestId(requestId);

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        keyName: `${file[0].name}`,
      },
    };

    axios(config)
      .then((res) => {
        delete axios.defaults.headers.common["Authorization"];
        const configSecondPart = {
          method: "put",
          url: res.data.response.presignedUrl,
          headers: {
            Accept: `${file[0].type}`,
            "Content-Type": `${file[0].type}`,
          },
          data: file[0],
          cancelToken: cancelFile.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };
        axios(configSecondPart)
          .then((res) => {
            delete axios.defaults.headers.common["Authorization"];
            const configThirdPart = {
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
              },
              data: {
                keyName: file[0].name,
                projectCode: id,
              },
            };

            axios(configThirdPart)
              .then((res) => {})
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else {
              console.log("AXIOS ERROR 2: ", err);
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(`Request ${requestId} canceled`);
        } else {
          console.log("AXIOS ERROR 1: ", err);
        }
      });
  };

  const [currentFileImage, setCurrentFileImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setChatbotIcon(event.target.files);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCurrentFileImage(imageUrl);
    } else {
      setCurrentFileImage(null);
    }
  };

  return (
    <>
      <div className='card'>
        <div className='card-body h-100 '>
          <div className='row d-flex '>
            <div className='col-12 my-3'>
              <label className='form-label' htmlFor='titoloProg'>
                Titolo del progetto
              </label>
              <input
                type='text'
                id='titoloProg'
                className='form-control form-control-lg fs-6'
                value={titoloProgetto || ""}
                onChange={(event) => setTitoloProgetto(event.target.value)}
              />
            </div>
            <div className='col-12 my-3'>
              <label className='form-label' htmlFor='istruzioniChatbot'>
                Istruzioni Assistente
              </label>
              <textarea
                type='text'
                placeholder="Dai istruzioni al tuo bot su cosa dovrebbe fare (esempio: sei l'assistente dell'azienda Gamma, rispondi alle domande degli utenti)"
                id='istruzioniChatbot'
                className='form-control form-control-lg fs-6'
                style={{
                  // maxHeight: "250px",
                  minHeight: "220px",
                  // overflowY: "hidden",
                }}
                value={istruzioniChatbot || ""}
                onChange={(event) => setIstruzioniChatbot(event.target.value)}
              />
              <p className='text-muted my-2'>
                Dai istruzioni al tuo bot su cosa dovrebbe fare (esempio: sei
                l'assistente dell'azienda Gamma, rispondi alle domande degli
                utenti).
              </p>
            </div>
            <div className='col-12 col-lg-6 my-3'>
              <label className='form-label' htmlFor='emailNotifyTo'>
                Invia email di notifica a:
              </label>
              <input
                type='email'
                name='email'
                autoComplete='on'
                id='emailNotifyTo'
                placeholder='Inserisci email'
                className='form-control form-control-lg fs-6'
                value={notifyTo || ""}
                onChange={(event) => setNotifyTo(event.target.value)}
                required
              />
              <p className='text-muted my-2'>
                Se valorizzato, viene inviata una mail di notifica all'avvio di
                una nuova conversazione
              </p>
            </div>

            <div className='col-12 col-lg-6 mb-3 d-flex justify-content-center justify-content-lg-start align-items-end py-3'>
              <div className='form-group text-center text-lg-start'>
                <label htmlFor='number'>Creatività:</label>
                <div className='d-flex gap-2 mt-2 justify-content-center justify-content-lg-start'>
                  <button
                    type='button'
                    className={`btn bg-green-second text-white`}
                    onClick={diminuisciTemperatura}
                    disabled={isTemperaturaMinima}
                  >
                    -
                  </button>

                  <input
                    type='text'
                    className='form-control w-auto'
                    id='number'
                    value={temperatura}
                    onChange={(event) => setTemperatura(event.target.value)}
                  />
                  <button
                    type='button'
                    className={`btn bg-green-second text-white ${
                      isTemperaturaMassima ? "disabled" : ""
                    }`}
                    onClick={aumentaTemperatura}
                    disabled={isTemperaturaMassima}
                  >
                    +
                  </button>
                </div>
                <p className='text-muted my-2'>
                  Regola la casualità e la diversità delle risposte generate
                  dall'intelligenza artificiale.
                </p>
              </div>
            </div>
            <div className='col-12 col-lg-6 mb-3 d-flex justify-content-start align-items-start py-3'>
              <div className='form-group text-center text-lg-start'>
                <label htmlFor='customRange3' className='form-label'>
                  Profondità di analisi:
                </label>
                <div className='d-flex gap-2 mt-2'>
                  <input
                    type='range'
                    className='form-range w-100 text'
                    min='1'
                    max='5'
                    step='1'
                    id='customRange3'
                    value={topK}
                    onChange={RangeTopK}
                  ></input>
                  <p className='px-2 m-0'>{topK}</p>
                </div>
                <div className='flex-fill justify-content-end'>
                  <p className='text-muted my-2'>
                    La risposta viene formulata considerando un maggior numero
                    di contenuti. Indica il numero di crediti consumati per ogni
                    risposta
                  </p>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-6 mb-3 d-flex justify-content-start align-items-end py-3'>
              <div className='form-group text-center text-lg-start'>
                <label htmlFor='messaggiStorico' className='form-label'>
                  Numero messaggi storico:
                </label>
                <div className='d-flex gap-2 mt-2'>
                  <input
                    type='range'
                    className='form-range w-100 text'
                    min='0'
                    max='5'
                    step='1'
                    id='messaggiStorico'
                    value={messageHistoryLimit}
                    onChange={RangeStoricoConversazioni}
                  ></input>
                  <p className='px-2 m-0'>{messageHistoryLimit}</p>
                </div>
                <div className='flex-fill justify-content-end'>
                  <p className='text-muted my-2'>
                  Il parametro definisce il numero massimo di messaggi (domande e risposte) che l'assistente virtuale conserverà in memoria. Questi messaggi verranno utilizzati per mantenere il contesto della conversazione e migliorare la continuità delle risposte.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 d-flex align-items-center gap-2 justify-content-center justify-content-lg-end my-3'>
            {/* <a href='' className='btn bg-rosso text-white'>
            Cancella
          </a> */}
            <a
              href=''
              className='btn bg-green-second text-white'
              onClick={(event) => AggiornaProgetto(event)}
            >
              Aggiorna
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComportamentoAssistente;
