import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import "moment/locale/it";
import ModalSuccess from "../Modals/ModalSuccess";
import ModalNotificationError from "../Modals/ModalNotificationError";

const Database = ({ dettagliProgetto }) => {
  const { id } = useParams();
  const {
    endpointDatabase,
    setEndpointDatabase,
    nomeDatabase,
    setNomeDatabase,
    usernameDatabase,
    setUsernameDatabase,
    passwordDatabase,
    setPasswordDatabase,
    istruzioniDatabase,
    setMysqlPostProcessingMessage,
    mysqlPostProcessingMessage,
    token,
    setError,
    setIstruzioniDatabase,
    mysqlPostProcessingMessageEnable,
    setMysqlPostProcessingMessageEnable,
    setLoading,
  } = useContext(StateContext);

  const [successCaricamentoInfoDatabase, setSuccessCaricamentoInfoDatabase] = useState(false)
  const [errorCaricamentoInfoDatabase, setErrorCaricamentoInfoDatabase] = useState(false)
  const [messageError, setMessageError] = useState("")

  
  const AggiornaDatabaseProgetto = async (event) => {
    setLoading(true);
    setErrorCaricamentoInfoDatabase(false);
    setSuccessCaricamentoInfoDatabase(false); // Imposta inizialmente a false
  
    try {
      // Prima chiamata: verifica credenziali database
      const checkResponse = await axios({
        method: "post",
        url: `${process.env.REACT_APP_PYTHON_ENGINE}/check-mysql-credentials`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          user: usernameDatabase,
          endpoint: endpointDatabase,
          database: nomeDatabase,
          password: passwordDatabase,
        },
      });
  
      if (checkResponse.data.status === "error") {
        // Se c'è un errore nella risposta, imposta messaggio e stato di errore
        setMessageError(checkResponse.data.message || "Errore nella verifica credenziali");
        setErrorCaricamentoInfoDatabase(true);
        setLoading(false);
        return; // Esce dalla funzione se la verifica fallisce
      }
  
      // Seconda chiamata: aggiornamento progetto
      const updateResponse = await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/update`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        data: {
          projectCode: id,
          mysqlUser: usernameDatabase,
          mysqlEndpoint: endpointDatabase,
          mysqlDatabase: nomeDatabase,
          mysqlPassword: passwordDatabase,
          mysqlPromptInstruction: istruzioniDatabase,
          mysqlPostProcessingMessageEnable: mysqlPostProcessingMessageEnable,
          mysqlPostProcessingMessage: mysqlPostProcessingMessageEnable ? mysqlPostProcessingMessage : "",
        },
      });
  
      if (updateResponse.status === 200) {
        setLoading(false);
        setSuccessCaricamentoInfoDatabase(true);
      } else if (updateResponse.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        window.location.replace("/login");
      }
    } catch (err) {
      // Gestione errori generici per entrambe le chiamate
      console.error("Errore nella richiesta", err);
      setErrorCaricamentoInfoDatabase(true);
      setLoading(false);
    }
  };
  

  return (
    <>
    <div className='position-relative h-100 d-flex flex-column '>
    {successCaricamentoInfoDatabase ? (
            <ModalSuccess
              title={"I dati inseriti sono stati aggiornati"}
              message={"Le informazioni dell'agente Database sono state aggiornate"}
              FunctionRemoveModal={setSuccessCaricamentoInfoDatabase}
            />
          ) : (
            ""
        )}
      {errorCaricamentoInfoDatabase ? (
          <ModalNotificationError
            title={"Si è verificato un errore"}
            message={messageError}
            FunctionRemoveModal={setErrorCaricamentoInfoDatabase}
          />
        ) : (
          ""
        )}
      
      <div className='row'>
        <div className='col-12'>
          <div className=''>
            <label>Endpoint</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci l'indirizzo a cui connettersi.
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={endpointDatabase}
              onChange={(event) => setEndpointDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Database</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>Inserisci il nome del database</p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={nomeDatabase}
              onChange={(event) => setNomeDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Username</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci lo username del tuo database
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={usernameDatabase}
              onChange={(event) => setUsernameDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Password</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci la password del tuo database
              </p>
            </div>
            <input
              id={`inputTitolo`}
              type='text'
              placeholder='Scrivi il titolo di riferimento'
              className='w-100 p-2 mt-2 rounded inputTitolo'
              value={passwordDatabase}
              onChange={(event) => setPasswordDatabase(event.target.value)}
            />
          </div>

          <div className='mt-3'>
            <label>Istruzioni di contesto</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci le istruzioni per addestrare l'assistente a ricercare
                le informazioni corrette nel tuo database.
              </p>
            </div>
            <textarea
              style={{ height: "auto", minHeight: "300px" }}
              id={`inputTesto`}
              placeholder='Scrivi o copia incolla il testo'
              className='w-100 p-2 my-2 rounded inputTesto'
              value={istruzioniDatabase}
              onChange={(event) => setIstruzioniDatabase(event.target.value)}
            />
          </div>


          <div className='col-12 my-3 '>
          <label className='form-label' htmlFor='flexCheckChecked'>
            Abilita messaggio post processo
          </label>
          <div
            className='form-switch d-flex align-items-center'
            style={{ height: "42px" }}
          >
            <input
              className='form-check-input'
              type='checkbox'
              role='switch'
              id='flexSwitchCheckDisabled'
              value={mysqlPostProcessingMessageEnable}
              checked={mysqlPostProcessingMessageEnable ? "checked" : ""}
              onChange={() => setMysqlPostProcessingMessageEnable(!mysqlPostProcessingMessageEnable)}
            />
          </div>
        </div>


          {
            mysqlPostProcessingMessageEnable &&
            <div className='mt-3'>
            <label>Messaggio Post Processo</label>

            <div className='d-flex align-items-center justify-content-between gap-2'>
              <p className='text-muted my-1'>
                Inserisci le istruzioni per il messaggio di post processo
              </p>
            </div>
            <textarea
              style={{ height: "auto", minHeight: "300px" }}
              id={`inputTesto`}
              placeholder='Scrivi o copia incolla il testo'
              className='w-100 p-2 my-2 rounded inputTesto'
              value={mysqlPostProcessingMessage}
              onChange={(event) => setMysqlPostProcessingMessage(event.target.value)}
            />
          </div>
          }

          <button
            className={`btn text-white bg-green-second `}
            onClick={AggiornaDatabaseProgetto}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
   
    </>
  );
};

export default Database;
