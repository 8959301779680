import React, { useEffect } from "react";
import { useContext } from "react";
import { StateContext } from "./StateContainer";
const NavbarModifica = ({ dettagliProgetto}) => {

const {
  setLoading,
  setError,
  token,
  titoloProgetto,
  aggiornamentoDatiProgetto,
  setAggiornamentoDatiProgetto,
  setPageTitle,
  windowWidth,
  cambiaSezioneNavbar,
  setCambiaSezioneNavbar,
  setTitoloProgetto,
  width,

  // Database
  endpointDatabase,
  setEndpointDatabase,
  nomeDatabase,
  setNomeDatabase,
  usernameDatabase,
  setUsernameDatabase,
  passwordDatabase,
  setPasswordDatabase,
  istruzioniDatabase,
  setIstruzioniDatabase,
  setMysqlPostProcessingMessage,
  setMysqlPostProcessingMessageEnable
} = useContext(StateContext);

  let type = dettagliProgetto[0] && dettagliProgetto[0].type

  return (
    <>
      <nav className='navbar w-100 p-0 m-0'>
        <div className='container w-100 p-0 m-0'>
          <div className='row w-100 d-flex align-items-center justify-content-between justify-content-lg-start p-0 m-0'>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type ==="mysql-agent" ? "d-none": ""}`}>
              <a
                href='#sitoWeb'
                className={`nav-link  ${
                  cambiaSezioneNavbar === "#sitoWeb"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#sitoWeb'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#sitoWeb");
                }}
              >
                Sito Web
              </a>
            </div>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type ==="mysql-agent" ? "d-none": ""}`}>
              <a
                href='#documenti'
                className={`nav-link ${
                  cambiaSezioneNavbar === "#documenti"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#documenti'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#documenti");
                }}
              >
                Documenti
              </a>
            </div>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type ==="mysql-agent" ? "d-none": ""}`}>
              <a
                href='#questionAndAnswers'
                className={`nav-link ${
                  cambiaSezioneNavbar === "#questionAndAnswers"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#questionAndAnswers'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#questionAndAnswers");
                }}
              >
                Q & A
              </a>
            </div>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type ==="mysql-agent" ? "d-none": ""}`}>
              <a
                href='#testoLibero'
                className={`nav-link ${
                  cambiaSezioneNavbar === "#testoLibero"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#testoLibero'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#testoLibero");
                }}
              >
                Testo Libero
              </a>
            </div>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type !=="conversational" ? "d-none": ""}`}>
              <a
                href='#nodiConversazione'
                className={`nav-link ${
                  cambiaSezioneNavbar === "#nodiConversazione"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#nodiConversazione'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#nodiConversazione");
                }}
              >
                Conversazione
              </a>
            </div>
            <div className={`col-6 col-lg-auto nav-item list-group-item navbar-modifica-item ${type !== "conversational" && type !=="mysql-agent" ? "d-none": ""}`}>
              <a
                href='#database'
                className={`nav-link ${
                  cambiaSezioneNavbar === "#database"
                    ? "active-modifica-contenuti"
                    : ""
                }`}
                value='#database'
                onClick={(link) => {
                  setCambiaSezioneNavbar("#database");
                }}
              >
                Database
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarModifica;
