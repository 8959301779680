import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./tailorgpt-css.css";
import AvatarPink from "./img/avatarPink.png";
import Cookies from "js-cookie";

const DemoCustomer = () => {
  const [backGroundImageDesktop, setBackGroundImageDesktop] = useState("");
  const [backGroundImageMobile, setBackGroundImageMobile] = useState("");
  const [responseServizioCodice, setResponseServizioCodice] = useState({});
  const [projectDetail, setProjectDetail] = useState({});

  let overlay = document.getElementById("overlay");
  let iframeContainer = document.getElementById("iframeContainer");
  let buttonChatbot = document.getElementById("buttonChatbot");
  let iframe = document.getElementById("iframe");
  let containerMessages = document.getElementById("containerMessages");
  const [showMessages, setShowMessages] = useState(true);
  let urlCode = new URL(window.location.href);
  let customerCode = urlCode.searchParams.get("code");

  const iframeContainerRef = useRef(null);

  useEffect(() => {
    const iframeContainer = iframeContainerRef.current;
    if (iframeContainer) {
      const updateHeight = () => {
        // Controlla se la larghezza dello schermo è maggiore di 600px
        if (window.innerWidth > 600) {
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;
          const newIframeContainerHeight = windowHeight - 150; // Puoi regolare il margine come preferisci
          iframeContainer.style.maxHeight = newIframeContainerHeight + "px";
        }
      };

      // Aggiorna l'altezza quando la finestra viene ridimensionata
      window.addEventListener("resize", updateHeight);
      // Aggiorna l'altezza quando il componente viene montato
      updateHeight();

      return () => {
        // Rimuovi l'event listener quando il componente viene smontato
        window.removeEventListener("resize", updateHeight);
      };
    }
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PUBLIC_URL}/contact/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        code: customerCode,
      },
    })
      .then((response) => {
        let risposta = response.data.response.contact;
        setResponseServizioCodice(risposta);
        setBackGroundImageDesktop(risposta.websiteScreenshotDesktop);
        setBackGroundImageMobile(risposta.websiteScreenshotMobile);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_PUBLIC_URL}/project/detail`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            projectCode: risposta.project,
          },
        })
          .then((response) => {
            let rispostaProject = response.data.response;
            setProjectDetail(rispostaProject);
            if (response.status === 200) {
              DettagliProgetto(rispostaProject);
            }
          })

          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const DettagliProgetto = (rispostaProject) => {
    let overlay = document.getElementById("overlay");
    let iframeContainer = document.getElementById("iframeContainer");
    let buttonChatbot = document.getElementById("buttonChatbot");
    let iframe = document.getElementById("iframe");
    let containerMessages = document.getElementById("containerMessages");

    if (
      rispostaProject.chatBubbleAlignement === "dx" &&
      window.innerWidth > 600
    ) {
      iframeContainer.style.right = "0";
      iframeContainer.style.left = "auto";
      iframeContainer.style.top = "auto";
      iframeContainer.style.marginBottom = "20px";
      iframeContainer.style.marginRight = "15px";
      iframeContainer.style.bottom = "0%";
      iframeContainer.style.transform = "translate(0%, 0%)";
      iframe.style.width = "100%";
      iframe.style.paddingBottom = "70px";

      buttonChatbot.style.transition = "all 250ms";
      containerMessages.style.transition = "all 250ms";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.right = "0px";
        containerMessages.style.right = "0px";
      }, 100);
    } else if (
      rispostaProject.chatBubbleAlignement === "sx" &&
      window.innerWidth > 600
    ) {
      containerMessages.classList.remove("firstMessageRight");
      containerMessages.classList.add("firstMessageLeft");

      buttonChatbot.classList.add("buttonChatbotLeft");
      buttonChatbot.classList.remove("buttonChatbotRight");

      iframe.style.height = "100%";
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const newIframeContainerHeight = windowHeight - 20;
      iframeContainer.style.maxHeight = newIframeContainerHeight + "px";

      iframeContainer.style.left = "auto";
      iframeContainer.style.top = "auto";
      iframeContainer.style.marginBottom = "20px";
      iframeContainer.style.marginLeft = "15px";
      iframeContainer.style.bottom = "0%";
      iframeContainer.style.transform = "translate(0%, 0%)";
      iframe.style.width = "400px";
      iframe.style.paddingBottom = "70px";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.left = "0px";
      }, 100);
    } else if (
      rispostaProject.chatBubbleAlignement === "sx" &&
      window.innerWidth <= 600
    ) {
      buttonChatbot.classList.add("buttonChatbotLeft");
      buttonChatbot.classList.remove("buttonChatbotRight");

      iframeContainer.style.left = "50%";
      iframeContainer.style.marginBottom = "0px";
      iframeContainer.style.marginLeft = "0px";
      iframeContainer.style.top = "50%";
      iframeContainer.style.bottom = "auto";
      iframeContainer.style.transform = "translate(-50%, -50%)";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.paddingBottom = "70px";
      buttonChatbot.style.transform = "scaleX(-1)";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.left = "0px";
      }, 100);
    } else {
      containerMessages.classList.remove("firstMessageLeft");
      containerMessages.classList.add("firstMessageRight");
      containerMessages.style.display = "none";

      buttonChatbot.classList.remove("buttonChatbotLeft");
      buttonChatbot.classList.add("buttonChatbotRight");

      iframeContainer.style.left = "50%";
      iframeContainer.style.right = "auto";
      iframeContainer.style.marginBottom = "0px";
      iframeContainer.style.marginLeft = "0px";
      iframeContainer.style.top = "50%";
      iframeContainer.style.bottom = "auto";
      iframeContainer.style.transform = "translate(-50%, -50%)";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.paddingBottom = "70px";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.right = "0px";
      }, 100);
    }
  };

  const HideWelcomeMessages = () => {
    if (
      projectDetail.chatBubbleAlignement === "dx" &&
      window.innerWidth > 600
    ) {
      Cookies.set("mostraMessaggio", false);
      containerMessages.style.right = "-400px";
      containerMessages.style.transition = "all 250ms";
    } else if (
      projectDetail.chatBubbleAlignement === "sx" &&
      window.innerWidth > 600
    ) {
      Cookies.set("mostraMessaggio", false);
      containerMessages.style.left = "400px";
      containerMessages.style.transition = "all 250ms";
    }
  };

  const OpenChatbot = () => {
    // containerMessages.style.display = "none";
    iframeContainer.style.display = "block";
    iframeContainer.style.opacity = "1";
    overlay.style.display = "block";
    setTimeout(function () {
      iframeContainer.classList.add("iframeContainerSizes");
      overlay.style.opacity = "1";
    }, 200);
    buttonChatbot.style.display = "none";
    containerMessages.style.display = "none";
    setShowMessages(false);
    Cookies.set("mostraMessaggio", false);
    // containerMessages.style.display = "none";

    if (
      projectDetail.chatBubbleAlignement === "dx" &&
      window.innerWidth > 600
    ) {
      iframeContainer.style.right = "0";
      iframeContainer.style.left = "auto";
      iframeContainer.style.top = "auto";
      iframeContainer.style.marginBottom = "20px";
      iframeContainer.style.marginRight = "15px";
      iframeContainer.style.bottom = "0%";
      iframeContainer.style.transform = "translate(0%, 0%)";
      iframe.style.width = "100%";
      iframe.style.paddingBottom = "70px";

      buttonChatbot.style.transition = "all 250ms";
      containerMessages.style.transition = "all 250ms";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.right = "0px";
        containerMessages.style.right = "0px";
      }, 100);
    } else if (
      projectDetail.chatBubbleAlignement === "sx" &&
      window.innerWidth > 600
    ) {
      containerMessages.classList.remove("firstMessageRight");
      containerMessages.classList.add("firstMessageLeft");

      buttonChatbot.classList.add("buttonChatbotLeft");
      buttonChatbot.classList.remove("buttonChatbotRight");

      iframe.style.height = "100%";
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const newIframeContainerHeight = windowHeight - 20;
      iframeContainer.style.maxHeight = newIframeContainerHeight + "px";

      iframeContainer.style.left = "auto";
      iframeContainer.style.top = "auto";
      iframeContainer.style.marginBottom = "20px";
      iframeContainer.style.marginLeft = "15px";
      iframeContainer.style.bottom = "0%";
      iframeContainer.style.transform = "translate(0%, 0%)";
      iframe.style.width = "400px";
      iframe.style.paddingBottom = "70px";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.left = "0px";
      }, 100);
    } else if (
      projectDetail.chatBubbleAlignement === "sx" &&
      window.innerWidth <= 600
    ) {
      buttonChatbot.classList.add("buttonChatbotLeft");
      buttonChatbot.classList.remove("buttonChatbotRight");

      iframeContainer.style.left = "50%";
      iframeContainer.style.marginBottom = "0px";
      iframeContainer.style.marginLeft = "0px";
      iframeContainer.style.top = "50%";
      iframeContainer.style.bottom = "auto";
      iframeContainer.style.transform = "translate(-50%, -50%)";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.paddingBottom = "70px";
      buttonChatbot.style.transform = "scaleX(-1)";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.left = "0px";
      }, 100);
    } else {
      containerMessages.classList.remove("firstMessageLeft");
      containerMessages.classList.add("firstMessageRight");
      containerMessages.style.display = "none";

      buttonChatbot.classList.remove("buttonChatbotLeft");
      buttonChatbot.classList.add("buttonChatbotRight");

      iframeContainer.style.left = "50%";
      iframeContainer.style.right = "auto";
      iframeContainer.style.marginBottom = "0px";
      iframeContainer.style.marginLeft = "0px";
      iframeContainer.style.top = "50%";
      iframeContainer.style.bottom = "auto";
      iframeContainer.style.transform = "translate(-50%, -50%)";
      iframe.style.width = "100%";
      iframe.style.height = "100%";
      iframe.style.paddingBottom = "70px";

      setTimeout(() => {
        buttonChatbot.style.opacity = "1";
        buttonChatbot.style.right = "0px";
      }, 100);
    }
    var message = { action: "reload", content: true };
    iframe.contentWindow.postMessage(message, "*");
  };

  const CloseChatbot = () => {
    if (Cookies.get("mostraMessaggio") === false) {
      containerMessages.style.display = "none";
    }
    iframeContainer.style.opacity = "0";
    overlay.style.opacity = "0";
    setTimeout(function () {
      iframeContainer.style.display = "none";
      iframeContainer.classList.remove("iframeContainerSizes");
      overlay.style.display = "none";
    }, 250);
    buttonChatbot.style.display = "block";
  };

  return (
    <>
      <div
        className='container-fluid'
        style={{
          background: `url(${
            window.innerWidth > 600
              ? backGroundImageDesktop
              : backGroundImageMobile
          }) top center / cover no-repeat`,
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
            transition: "opacity 250ms ease 0s",
            display: "none",
          }}
          className='test'
          id='overlay'
          onClick={CloseChatbot}
        >
          <div
            className='iframeContainerBefore'
            ref={iframeContainerRef}
            style={{
              inset: "auto 0px 0% auto",
              marginBottom: "20px",
              marginRight: "15px",
              transform: "translate(0%, 0%)",
              borderRadius: "20px",
              position: "absolute",
              backgroundColor: "transparent",
              overflow: "hidden",
              display: "none",
            }}
            id='iframeContainer'
          >
            <div
              style={{
                width: "100%",
                height: "90px",
                backgroundColor: `${projectDetail.primaryColor}`,
                textAlign: "right",
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div
                  style={{
                    background: `url(${
                      projectDetail.chatbotIcon || AvatarPink
                    }) center center / cover no-repeat`,
                    borderRadius: "100%",
                    width: "60px",
                    height: "60px",
                  }}
                ></div>
                <div style={{ textAlign: "left" }}>
                  <div
                    style={{
                      margin: "0px",
                      fontSize: "14pt",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {projectDetail.title}
                  </div>
                  <p
                    style={{ margin: "0px", color: "white", fontSize: "11pt" }}
                  >
                    Hai una domanda?
                  </p>
                </div>
              </div>
              <button
                style={{
                  background: "transparent",
                  height: "30px",
                  width: "30px",
                  border: "0px",
                  color: "white",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                onClick={CloseChatbot}
              >
                ✕
              </button>
            </div>

            <iframe
              src={`${process.env.REACT_APP_CHATBOT}?projectCode=${responseServizioCodice.project}`}
              id='iframe'
            ></iframe>
          </div>
        </div>
      </div>

      {/* Messaggi  */}
      <div
        style={{ opacity: 1 }}
        className={`d-none ${
          !Cookies.get("mostraMessaggio") && showMessages
            ? "d-sm-flex flex-column"
            : ""
        } gap-2 py-3 ${
          window.innerWidth > 600
            ? projectDetail.chatBubbleAlignement === "dx"
              ? "firstMessageRight"
              : projectDetail.chatBubbleAlignement === "sx"
              ? "firstMessageLeft"
              : ""
            : ""
        }`}
        id='containerMessages'
      >
        {projectDetail && projectDetail.initialMessage
          ? projectDetail.initialMessage.split("\n").map((message, index) => {
              return (
                <div className='paragraphDiv mb-0' key={index}>
                  <p>{message}</p>
                  <button
                    style={{
                      right: "0px",
                      top: "-10px",
                      color: `${projectDetail.primaryColor}`,
                      border: `1px solid ${projectDetail.primaryColor}`,
                    }}
                    className='buttonCloseFirstMessage'
                    onClick={HideWelcomeMessages}
                  ></button>
                </div>
              );
            })
          : ""}
      </div>
      {/* Bottone  */}
      <button
        className={
          projectDetail.chatBubbleAlignement === "dx"
            ? "buttonChatbotRight"
            : "buttonChatbotLeft"
        }
        id='buttonChatbot'
        style={{
          position: "fixed",
          outline: "none",
          bottom: "0px",
          border: "0px",
          backgroundColor: "transparent",
          backgroundSize: "cover",
          zIndex: "999999",
          borderRadius: "0%",
        }}
        onClick={OpenChatbot}
      >
        <svg
          id='Livello_2'
          data-name='Livello 2'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 150 150'
          // fill='red'
          fill={`${projectDetail.primaryColor}`}
        >
          <defs>
            <style>
              {`
        .cls-1, .cls-2 {
          stroke-width: 0px;
        }
        .cls-2 {
          fill: #fff;
        }
      `}
            </style>
          </defs>
          <g id='Livello_1-2' data-name='Livello 1'>
            <path
              className='cls-2'
              d='m75,134.5c-32.86,0-59.5-26.64-59.5-59.5S42.14,15.5,75,15.5s59.5,26.64,59.5,59.5-26.64,59.5-59.5,59.5Z'
            ></path>
            <path
              className='cls-1'
              d='m75,0C33.58,0,0,33.58,0,75s32.09,75,73.51,75h76.49v-75C150,33.58,116.42,0,75,0Zm0,134.5c-32.86,0-59.5-26.64-59.5-59.5S42.14,15.5,75,15.5s59.5,26.64,59.5,59.5-26.64,59.5-59.5,59.5Zm-20.72-59.5c0,6.34-5.14,11.49-11.49,11.49s-11.49-5.14-11.49-11.49,5.14-11.49,11.49-11.49,11.49,5.14,11.49,11.49Zm32.2,0c0,6.34-5.14,11.49-11.49,11.49s-11.49-5.14-11.49-11.49,5.14-11.49,11.49-11.49,11.49,5.14,11.49,11.49Zm32.2,0c0,6.34-5.14,11.49-11.49,11.49s-11.49-5.14-11.49-11.49,5.14-11.49,11.49-11.49,11.49,5.14,11.49,11.49Z'
            ></path>
          </g>
        </svg>
      </button>
    </>
  );
};

export default DemoCustomer;
