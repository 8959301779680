import React, { useState, createContext } from "react";
import Cookies from "js-cookie";

export const StateContext = createContext();

const StateContainer = ({ children }) => {

  // Domanda Sezione FAQ
  const [domanda, setDomanda] = useState("");

  // Leads Table
  const [leadTable, setLeadTable] = useState([]);
  const [visualizzaAnteprimaChat, setVisualizzaAnteprimaChat] = useState(false);

  const [queries, setQueries] = useState([]);
  const [aggiornaDomanda, setAggiornaDomanda] = useState("");
  const [allProjectInformation, setAllProjectInformation] = useState([]);
  const [chatbotIcon, setChatbotIcon] = useState("");
  const [aggiornaRisposta, setAggiornaRisposta] = useState("");
  const [currentIndexDocument, setCurrentIndexDocument] = useState(null);
  const [textOfTheDocument, setTextOfTheDocument] = useState("");
  const [messageError, setMessageError] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [conversationCode, setConversationCode] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("Dashboard");
  const [projectTitle, setProjectTitle] = useState("");
  const [filtraConversazioni, setFiltraConversazioni] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nascondiSidebar, setNascondiSidebar] = useState(
    window.innerWidth > 992 ? false : true
  );
  const [listOfProjects, setListOfProjects] = useState([]);
  const [dominioDelSito, setDominioDelSito] = useState("");
  const token = localStorage.getItem("token");
  const [nuovoProgetto, setNuovoProgetto] = useState([]);
  const [aggiornamentoDatiProgetto, setAggiornamentoDatiProgetto] =
    useState("");
  const [topK, setTopK] = useState(1);
  const[messageHistoryLimit,
    setMessageHistoryLimit] = useState(2)
  const [notifyTo, setNotifyTo] = useState("");
  const [titoloProgetto, setTitoloProgetto] = useState("");
  const [linkDiretto, setLinkDiretto] = useState("");
  const [temperatura, setTemperatura] = useState("");
  const [istruzioniChatbot, setIstruzioniChatbot] = useState("");
  const [primoMessaggioBot, setPrimoMessaggioBot] = useState("");
  const [coloreMessaggi, setColoreMessaggi] = useState("");
  const [eliminaProgetto, setEliminaProgetto] = useState([]);
  const [allineamentoBottoneChatbot, setAllineamentoBottoneChatbot] =
    useState("");
  const [codiceProgetto, setCodiceProgetto] = useState("");
  const [assistantName, setAssistantName] = useState("Assistant");
  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#ffffff");
  const [privacyLink, setPrivacyLink] = useState("");
  const [collectCustomerEmail, setCollectCustomerEmail] = useState("");
  const [userProfileData, setUserProfileData] = useState([]);
  const [profileLoading, setProfileLoading] = useState(false);
  const [cambiaSezioneNavbar, setCambiaSezioneNavbar] = useState("#sitoWeb");

  const [textOfTheLink, setTextOfTheLink] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);

  const [sectionMobile, setSectionMobile] = useState(() => {
    return Cookies.get("lastSectionMobile") || "#sitoWeb";
  });

  // Lead da inserire dall'utente
  const [collectLeadName, setCollectLeadName] = useState(false);
  const [collectLeadEmail, setCollectLeadEmail] = useState(false);
  const [collectLeadCompany, setCollectLeadCompany] = useState(false);
  const [collectLeadJobTitle, setCollectLeadJobTitle] = useState(false);
  const [collectLeadPhone, setCollectLeadPhone] = useState(false);
  const [collectLeadMessage, setCollectLeadMessage] = useState("");
  const [collectLeadWhen, setCollectLeadWhen] = useState("default");
  const [allowSkipContact, setAllowSkipContact] = useState(false);
  const [menuConversazioniPrecedenti, setMenuConversazioniPrecedenti] =
    useState(false);

  // Database const
  const [endpointDatabase, setEndpointDatabase] = useState("");
  const [nomeDatabase, setNomeDatabase] = useState("");
  const [usernameDatabase, setUsernameDatabase] = useState("");
  const [passwordDatabase, setPasswordDatabase] = useState("");
  const [istruzioniDatabase, setIstruzioniDatabase] = useState("");
  const [mysqlPostProcessingMessage, setMysqlPostProcessingMessage] = useState("");
  const [mysqlPostProcessingMessageEnable, setMysqlPostProcessingMessageEnable] = useState(false);


  // Sezione form di Contatto Aspetto Chat
  const [isSectionContactFormOpen, setIsSectionContactFormOpen] =
    useState(false);

  // Testo d'invio dei Leads per mostrare un esempio di come viene inviato il messaggio nell'Aspetto Chat
  const [sendLeadTextExample, setSendLeadTextExample] = useState("");

  let AllUserData = null;
  let userName = null;
  let userLastname = null;
  let userEmail = null;
  let userAvatar = null;
  let userPlanMaxProjects = 0;
  let userPlan = null;
  //Dati dello User Nei Cookie
  if (Cookies.get("userData")) {
    AllUserData = JSON.parse(Cookies.get("userData"));
    userName = `${AllUserData.name}`;
    userLastname = `${AllUserData.lastname}`;
    userEmail = `${AllUserData.email}`;
    userAvatar = AllUserData.avatar;
    userPlanMaxProjects = AllUserData.plan.maxProjects;
    userPlan = AllUserData.plan.name;
  }


  // Errori riscontrati a seguito di un'azione dell'utente
  const CheckCodiceProgetto = (dataCodice) => {
    let errorMessage = "";
    if (dataCodice.code === 1) {
      errorMessage = "Parametro mancante";
    } else if (dataCodice.code === 2) {
      errorMessage = "Numero massimo di progetti raggiunto";
    } else if (dataCodice.code === 3) {
      errorMessage = "Problemi di autenticazione";
    } else if (dataCodice.code === 4) {
      errorMessage = "Elemento non trovato";
    } else if (dataCodice.code === 5) {
      errorMessage = "Non hai i privilegi per accedere alla risorsa richiesta.";
    } else if (dataCodice.code === 6) {
      errorMessage = "Il link è già esistente";
    } else if (dataCodice.code === 7) {
      errorMessage = "Il documento è già esistente";
    } else if (dataCodice.code === 8) {
      errorMessage = "La conversazione non esiste";
    } else if (dataCodice.code === 9) {
      errorMessage = "Il valore non è valido";
    }
    return `Errore durante l'esecuzione della richiesta: ${errorMessage}`;
  };

  // Leads Data
  const leads = [
    {
      id: 1,
      fieldFor: "Name",
      type: "checkbox",
      role: "switch",
      value: collectLeadName,
      textLabel: "Richiedi Nome",
      spiegazione: "Se valorizzato, verrà richiesto il nome all'utente.",
      functionChange: () => setCollectLeadName(!collectLeadName),
    },
    {
      id: 2,
      fieldFor: "Email",
      type: "checkbox",
      value: collectLeadEmail,
      role: "switch",
      textLabel: "Richiedi Email",
      spiegazione: "Se valorizzato, verrà richiesta la e-mail all'utente.",
      functionChange: () => setCollectLeadEmail(!collectLeadEmail),
    },
    {
      id: 8,
      fieldFor: "Compagnia",
      type: "checkbox",
      value: collectLeadCompany,
      role: "switch",
      textLabel: "Richiedi Nome Compagnia",
      spiegazione:
        "Se valorizzato, verrà richiesto il nome dell'azienda per cui lavora dall'utente.",
      functionChange: () => setCollectLeadCompany(!collectLeadCompany),
    },
    {
      id: 3,
      fieldFor: "Lavoro",
      value: collectLeadJobTitle,
      type: "checkbox",
      role: "switch",
      textLabel: "Richiedi Professione",
      spiegazione:
        "Se valorizzato, verrà richiesto il tipo di Professione svolta dall'utente.",
      functionChange: () => setCollectLeadJobTitle(!collectLeadJobTitle),
    },

    {
      id: 4,
      fieldFor: "Telefono",
      value: collectLeadPhone,
      type: "checkbox",
      role: "switch",
      textLabel: "Richiedi Telefono",
      spiegazione:
        "Se valorizzato, verrà richiesto il numero di telefono dell'utente.",
      functionChange: () => setCollectLeadPhone(!collectLeadPhone),
    },
    {
      id: 5,
      fieldFor: "Messaggio di apertura del modulo di contatto",
      value: collectLeadMessage,
      type: "text",
      textLabel: "Inserisci il messaggio da mostrare nel form",
      placeholder: "Es.: Compila il seguente Form",
      spiegazione: "",
      functionChange: (e) => setCollectLeadMessage(e.target.value),
    },
    {
      id: 6,
      value: collectLeadWhen,
      name: "collectLeadWhen",
      fieldFor: "Quando Mostrare Form",
      type: "select",
      textLabel: "Scegli quando mostrare il Form all'utente",
      spiegazione: `- Apertura della chat: quando l'utente apre l'assistente viene immediatamente proposto il modulo di contatto.\n\n
      - Dopo interazione utente: il modulo di contatto è proposto dopo che l'utente ha inviato il suo primo messaggio.\n\n
      - Persisti Contatto: Il modulo di contatto verrà proposto nella chat per tutta la durata della conversazione.`,
      functionChange: (e) => setCollectLeadWhen(e.target.value),
      options: [
        {
          id: 1,
          name: "Nessuna opzione selezionata",
          value: "default",
        },
        {
          id: 2,
          name: "Dopo interazione utente",
          value: "first-response",
        },
        {
          id: 3,
          name: "All'apertura della chat",
          value: "before-chat",
        },
        {
          id: 4,
          name: "Nel flusso della Conversazione",
          value: "follow-conversation",
        },
      ],
    },
    {
      id: 7,
      fieldFor: "Permetti di Chiudere il Form",
      value: allowSkipContact,
      type: "checkbox",
      role: "switch",
      textLabel: "Consenti di saltare la compilazione del modulo",
      spiegazione:
        "Se abilitato l'utente potra saltare la compilazione della form, altrimenti è obbligatoria per procedere.",
      functionChange: () => setAllowSkipContact(!allowSkipContact),
    },
  ];
  return (
    <StateContext.Provider
      value={{
        // Domanda Sezione FAQ
        domanda, setDomanda,

        
        assistantName,
        setAssistantName,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        userPlanMaxProjects,
        userName,
        userLastname,
        userEmail,
        userAvatar,
        showDeleteModal,
        notifyTo,
        setNotifyTo,
        projectTitle,
        setProjectTitle,
        setShowDeleteModal,
        codiceProgetto,
        setCodiceProgetto,
        eliminaProgetto,
        setEliminaProgetto,
        topK,
        setTopK,
        messageHistoryLimit,
    setMessageHistoryLimit,
        pageTitle,
        allProjectInformation,
        setAllProjectInformation,
        setPageTitle,
        setConversationCode,
        conversationCode,
        aggiornamentoDatiProgetto,
        filtraConversazioni,
        setFiltraConversazioni,
        setAggiornamentoDatiProgetto,
        dominioDelSito,
        setDominioDelSito,
        nuovoProgetto,
        setNuovoProgetto,
        nascondiSidebar,
        privacyLink,
        setPrivacyLink,
        setNascondiSidebar,
        loading,
        setLoading,
        error,
        setError,
        token,
        setListOfProjects,
        listOfProjects,
        titoloProgetto,
        setTitoloProgetto,
        temperatura,
        setTemperatura,
        istruzioniChatbot,
        setIstruzioniChatbot,
        primoMessaggioBot,
        setPrimoMessaggioBot,
        coloreMessaggi,
        CheckCodiceProgetto,
        setColoreMessaggi,
        allineamentoBottoneChatbot,
        setAllineamentoBottoneChatbot,
        linkDiretto,
        setLinkDiretto,
        windowWidth,
        setWindowWidth,
        messageError,
        setMessageError,
        userPlan,
        AllUserData,
        collectCustomerEmail,
        setCollectCustomerEmail,
        userProfileData,
        setUserProfileData,
        profileLoading,
        setProfileLoading,
        cambiaSezioneNavbar,
        setCambiaSezioneNavbar,
        sectionMobile,
        setSectionMobile,
        textOfTheLink,
        setTextOfTheLink,
        currentIndex,
        setCurrentIndex,
        textOfTheDocument,
        setTextOfTheDocument,
        currentIndexDocument,
        setCurrentIndexDocument,
        aggiornaDomanda,
        setAggiornaDomanda,
        aggiornaRisposta,
        setAggiornaRisposta,
        chatbotIcon,
        setChatbotIcon,

        // Collect Lead
        collectLeadName,
        setCollectLeadName,
        collectLeadEmail,
        setCollectLeadEmail,
        collectLeadCompany,
        setCollectLeadCompany,
        collectLeadJobTitle,
        setCollectLeadJobTitle,
        collectLeadPhone,
        setCollectLeadPhone,
        collectLeadMessage,
        setCollectLeadMessage,
        collectLeadWhen,
        setCollectLeadWhen,
        allowSkipContact,
        setAllowSkipContact,

        // Leads Data
        leads,

        // Domande Predefinite
        queries,
        setQueries,

        // Sidebar conversazioni precedenti
        menuConversazioniPrecedenti,
        setMenuConversazioniPrecedenti,

        // Lead Table
        leadTable,
        setLeadTable,

        // Sezione form di Contatto Aspetto Chat
        isSectionContactFormOpen,
        setIsSectionContactFormOpen,

        // Testo d'invio dei Leads per mostrare un esempio di come viene inviato il messaggio nell'Aspetto Chat
        sendLeadTextExample,
        setSendLeadTextExample,

        // Visualizza Anteprima Chat
        visualizzaAnteprimaChat,
        setVisualizzaAnteprimaChat,

        // Database const
        endpointDatabase,
        setEndpointDatabase,
        nomeDatabase,
        setNomeDatabase,
        usernameDatabase,
        setUsernameDatabase,
        passwordDatabase,
        setPasswordDatabase,
        istruzioniDatabase,
        setIstruzioniDatabase,
        setMysqlPostProcessingMessage,
        mysqlPostProcessingMessage,
        mysqlPostProcessingMessageEnable,
    setMysqlPostProcessingMessageEnable,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContainer;
