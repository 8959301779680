import React, { useState, useEffect, useContext, useRef } from "react";
import { StateContext } from "./StateContainer";
import { useParams } from "react-router-dom";
import { TbDotsVertical as Dots, TbH1 } from "react-icons/tb";
import axios from "axios";
import DettagliSingoloProgetto from "./ComportamentoAssistente";
import LogoFulgeas from "../img/TailorGPTOriz.svg";
import AvatarPink from "../img/avatarPink.png";
import { marked } from "marked";  // Importa la libreria marked
import Moment from "react-moment";

const ChatbotConStorico = ({ title, allProjectInformation, setNavbarGeneralChange, setCambiaSezioneNavbar }) => {
  const { id } = useParams();
  const [caricamento, setCaricamento] = useState(false);
  const [dettagliProgetto, setDettagliProgetto] = useState([]);
  const cardBodyRef = useRef(null);


  console.log(dettagliProgetto)

  const {
    setLoading,
    setError,
    token,
    conversationCode,
    primoMessaggioBot,
    coloreMessaggi,
    setDomanda,
    domanda
  } = useContext(StateContext);

  const AggiungiFAQDaDomandaUtente = (testoDomanda) => {
    setNavbarGeneralChange("#caricaDocumenti")
    setCambiaSezioneNavbar("#questionAndAnswers")
    setDomanda(testoDomanda)
  }


  useEffect(() => {
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/conversation/detail`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        conversationCode: conversationCode,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setDettagliProgetto(response.data.response);
        }
      })
      .catch((err) => setError(err));
  }, [conversationCode]);

  // Colore esadecimale

  var hexPrimaryColor = null;
  var primaryRed = null;
  var primaryGreen = null;
  var primaryBlue = null;

  var primaryRGBA = null;

  if (allProjectInformation) {
    hexPrimaryColor = allProjectInformation.primaryColor;

    if (hexPrimaryColor) {
      // Estrai i componenti di rosso, verde, blu dall'hex
      primaryRed = parseInt(hexPrimaryColor.slice(1, 3), 16);
      primaryGreen = parseInt(hexPrimaryColor.slice(3, 5), 16);
      primaryBlue = parseInt(hexPrimaryColor.slice(5, 7), 16);

      primaryRGBA = `rgba(${primaryRed}, ${primaryGreen}, ${primaryBlue}, 0.1)`;
    }
  }

  const linkRegex = /(?<!=")(\b[\w]+:\/\/[\w-?&;#~=\.\/\@]+[\w\/])/;

  return (
    <div
      className='card card-storico-conversazioni'
      style={{ height: "100%", maxHeight: "800px" }}
    >
      <div
        ref={cardBodyRef}
        style={{ overflowY: "scroll" }}
        className='card-body containerMessagesChatbot'
      >
        <div className='chat-conversation py-3'>
          <ul
            className='list-unstyled mb-0 chat-conversation-message px-0'
            data-simplebar
            style={{ fontSize: "0.875rem" }}
          >
            <div className={``} style={{ color: `${coloreMessaggi}` }}>
              <li className='pb-2' id='primoMessaggio'>
                <div className='d-flex align-items-start gap-2'>
                  <div
                    style={{
                      minHeight: "16px",
                      minWidth: "16px",
                      borderRadius: "100%",
                      background: `url(${AvatarPink}) center center / contain no-repeat`,
                    }}
                    className='bg-danger'
                  ></div>
                  <div>
                    <h5 className='font-size-14 conversation-name'>
                      <a
                        href='#'
                        className='text-dark text-decoration-none'
                        style={{
                          color: `${
                            allProjectInformation
                              ? allProjectInformation.userMessageColor
                              : ""
                          }`,
                        }}
                      >
                        {allProjectInformation
                          ? allProjectInformation.assistantName
                          : ""}
                      </a>{" "}
                    </h5>

                    <div
                      className='conversation-list'
                      style={{ maxWidth: "400px" }}
                    >
                      <div className='ctext-wrap m-0'>
                        <div
                          className='ctext-wrap-content m-0'
                          style={{ background: `${primaryRGBA}` }}
                        >
                          <p className='mb-0'>{primoMessaggioBot}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {dettagliProgetto && dettagliProgetto.map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <li className='right'>
                      <div className='conversation-list'>
                        <div className='ctext-wrap m-0 d-flex justify-content-end'>
                          <div
                            className='ctext-wrap-content m-0 text-white'
                            style={{ background: `rgba(83, 83, 83, 1)` }}
                          >
                            <p className='mb-0 text-white'>{row.userText}</p>
                          </div>
                        </div>
                        <span className='d-inline-block text-end font-size-12 text-muted mt-2'>
                              <Moment format='D/M/Y HH:mm'>
                                {row.date && row.date.date}
                              </Moment>
                            </span>
                        
                      </div>
                    </li>
                    <li className="right P"><div className='conversation-list ps-0'>
                        <div className='ctext-wrap m-0'>
                          <div
                            className='ctext-wrap-content m-0 text-white pe-0'
                          >
                           <button className="btn bg-verde text-white" onClick={()=> AggiungiFAQDaDomandaUtente(row.userText)}>+ Aggiungi FAQ</button>
                          </div>
                        </div>
                        
                      </div>
                    </li>

                    <li className='pb-2 text-black'>
                      <div className='d-flex align-items-start gap-2'>
                        <div
                          style={{
                            minHeight: "16px",
                            minWidth: "16px",
                            borderRadius: "100%",
                            background: `url(${AvatarPink}) center center / contain no-repeat`,
                          }}
                          className='bg-danger'
                        ></div>
                        <div>
                          <h5 className='font-size-14 conversation-name'>
                            <a
                              href='#'
                              className='text-dark text-decoration-none'
                            >
                              {allProjectInformation
                                ? allProjectInformation.assistantName
                                : ""}
                            </a>{" "}
                          </h5>

                          <div className=''>
                            {row.botText
                              // .split(/(\.)(?=\s|$)/)
                              .split(/\n|<br>/)
                              // .filter((el) => !el.length < 1);
                              // .split(/\.|\s|$|<br>/)
                              // .split(/(\.)(?=\s|$ |\n|<br>)/)
                              .reduce((acc, sentence) => {
                                const lastElement = acc[acc.length - 1];
                                if (
                                  !lastElement ||
                                  lastElement.length + sentence.length > 250
                                ) {
                                  acc.push(sentence);
                                } else {
                                  acc[acc.length - 1] += sentence;
                                }
                                return acc;
                              }, [])
                              .map((paragraph, index) => {
                                // Check if the paragraph is not empty
                                if (paragraph.trim().length <= 1) {
                                  return null; // Skip rendering empty paragraphs
                                }

                                // Check if the paragraph starts with a period and remove it
                                const formattedParagraph = paragraph
                                  .trim()
                                  // .replace("<br>", "")
                                  .startsWith(".")
                                  ? paragraph.trim().slice(1)
                                  : paragraph.trim();

                                return (
                                  <div
                                    className='conversation-list m-0'
                                    style={{ maxWidth: "400px" }}
                                    key={index}
                                  >
                                    <div className='ctext-wrap mt-2'>
                                      <div
                                        className='ctext-wrap-content m-0'
                                        style={{
                                          background: `${primaryRGBA}`,
                                          maxWidth: "300px",
                                          hyphens: "auto",
                                        }}
                                      >
                                        <p
                                          className='mb-0'
                                          style={{
                                            color: `${
                                              dettagliProgetto
                                                ? dettagliProgetto.userMessageColor
                                                : ""
                                            }`,
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: marked(formattedParagraph)
                                              .replace(/\\/g, '"')
                                              .replace(linkRegex, (match) => {
                                                return `<a href="${match}" target="_blank">${match}</a>`;
                                              }),
                                          }}
                                        ></p>
                                     
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        
                      </div>
                      <div className="d-flex align-items-start gap-2">
                        <div style={{minHeight: "16px", minWidth: "16px"}}></div>
                      <span className='text-end font-size-12 text-muted '>
                              <Moment format='D/M/Y HH:mm'>
                                {row.date && row.date.date}
                              </Moment>
                            </span>
                      </div>
                    </li>
                  </React.Fragment>
                );
              })}

              <div className='pb-3'></div>
            </div>
          </ul>
        </div>
      </div>
      {caricamento ? (
        ""
      ) : (
        <div
          className={`py-4 card-footer chat-input-section d-flex flex-fill align-items-center w-100  justify-content-center bg-white footer-chat ${
            dettagliProgetto && dettagliProgetto.length > 0 ? "d-none" : ""
          }`}
        ></div>
      )}
    </div>
  );
};

export default ChatbotConStorico;
