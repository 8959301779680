import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "./StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import AvatarPink from "../img/avatarPink.png";
import ChatPreview from "./AspettoChat/ChatPreview";
import StylesOfTheChat from "./AspettoChat/sections/StylesOfTheChat";
import FormRequestContact from "./AspettoChat/sections/FormRequestContact";
import PredefinedQueriesSection from "./AspettoChat/sections/PredefinedQueriesSection";
import BasicInformationChat from "./AspettoChat/sections/BasicInformationChat";
import Loading from "./Loading";
import VisualizzaAnteprimaChat from "./AspettoChat/mobile/VisualizzaAnteprimaChat";

const AspettoChat = ({ progetto }) => {
  const { id } = useParams();
  const {
    loading,
    setLoading,
    setTitoloProgetto,
    setError,
    token,
    setAggiornamentoDatiProgetto,
    primoMessaggioBot,
    setPrimoMessaggioBot,
    coloreMessaggi,
    setColoreMessaggi,
    allineamentoBottoneChatbot,
    setAllineamentoBottoneChatbot,
    assistantName,
    setAssistantName,
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,
    privacyLink,
    setPrivacyLink,
    collectCustomerEmail,
    setCollectCustomerEmail,
    chatbotIcon,
    setChatbotIcon,

    // Collect Lead Fields
    collectLeadName,
    collectLeadEmail,

    collectLeadCompany,
    collectLeadJobTitle,

    collectLeadPhone,
    collectLeadMessage,

    collectLeadWhen,
    allowSkipContact,

    setQueries,

    // Domande Predefinite
    queries,

    setSendLeadTextExample,

    // Visualizza Anteprima Chat
    visualizzaAnteprimaChat,
    setVisualizzaAnteprimaChat,
  } = useContext(StateContext);

  useEffect(() => {
    let predefinedQueries = [];
    setSendLeadTextExample("");
    if (progetto[0]) {
      setTitoloProgetto(progetto[0].title);
      setPrimoMessaggioBot(progetto[0].initialMessage);
      setColoreMessaggi(progetto[0].userMessageColor);
      setAllineamentoBottoneChatbot(progetto[0].chatBubbleAlignement);
      setAssistantName(progetto[0].assistantName || "");
      setPrimaryColor(progetto[0].primaryColor || "");
      setSecondaryColor(progetto[0].secondaryColor || "");
      setPrivacyLink(progetto[0].privacy || "");
      setCollectCustomerEmail(progetto[0].collectCustomerEmail || "");
      if (progetto[0]) {
        progetto[0].predefinedQueries.map((query) => {
          predefinedQueries.push({ label: query[0], question: query[1] });
        });
        setQueries(predefinedQueries);
      }
    } else {
    }
  }, [progetto]);

  const AggiornaProgetto = (event) => {
    UploadChatbotIcon();
    const overlayDataModal = document.getElementById("overlayDataModal");

    event.preventDefault();

    let allPredefinedQueries = [];
    queries.forEach((query) => {
      return allPredefinedQueries.push(`"${query.label}||${query.question}"`);
    });


    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        domains: "",
        maxMessageNumber: "",
        maxMessageNumberPeriod: "",
        rateLimitMessage: "",
        initialMessage: primoMessaggioBot,
        userMessageColor: coloreMessaggi,
        chatBubbleAlignement: allineamentoBottoneChatbot,
        assistantName: assistantName,
        primaryColor: primaryColor,
        secondaryColor: secondaryColor,
        collectCustomerEmail: collectCustomerEmail,
        privacy: privacyLink,
        predefinedQueries: "[" + allPredefinedQueries + "]",
        allowSkipContact: collectCustomerEmail ? allowSkipContact : false,
        collectLeadCompany: collectCustomerEmail ? collectLeadCompany : false,
        collectLeadEmail: collectCustomerEmail ? collectLeadEmail : false,
        collectLeadJobTitle: collectCustomerEmail ? collectLeadJobTitle : false,
        collectLeadMessage: collectLeadMessage ? collectLeadMessage : "",
        collectLeadName: collectCustomerEmail ? collectLeadName : false,
        collectLeadPhone: collectCustomerEmail ? collectLeadPhone : false,
        collectLeadWhen: collectCustomerEmail ? collectLeadWhen : "default",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (response.status === 200) {
          setAggiornamentoDatiProgetto(response.status);
        }
      })
      .catch((err) => setError(err));
  };

  const [contentData, setContentData] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(null);

  const UploadChatbotIcon = () => {
    let file = chatbotIcon;
    setChatbotIcon("");

    if (file) {
      const targetWidth = 128;
      const targetHeight = 128;

      // Creare un elemento immagine per caricare il file
      const img = new Image();
      img.src = URL.createObjectURL(file[0]);

      img.onload = function () {
        const width = img.width;
        const height = img.height;

        // Crea un canvas con le dimensioni target
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        // Calcola il rapporto di ridimensionamento
        const scaleX = targetWidth / width;
        const scaleY = targetHeight / height;

        // Disegna l'immagine ridimensionata sul canvas con le dimensioni target
        ctx.drawImage(
          img,
          0,
          0,
          width,
          height,
          0,
          0,
          targetWidth,
          targetHeight
        );

        // Converti il canvas in un Blob
        canvas.toBlob((blob) => {
          // Il file è stato ridotto con successo
          const compressedFile = new File([blob], file[0].name, {
            type: file[0].type,
          });
          proceedWithUpload([compressedFile]);
        }, file[0].type);
      };
    } else {
      return;
    }
  };

  const proceedWithUpload = (file) => {
    const cancelFile = axios.CancelToken.source();
    setUploadProgress((prevProgress) => [
      ...prevProgress,
      {
        file: file[0],
        progress: 0,
        status: "uploading",
        cancelToken: cancelFile,
      },
    ]);
    setContentData((currentScene) => [...currentScene, file[0]]);

    const requestId = `upload_${file[0].name}`;
    setCurrentRequestId(requestId);

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/attachment-presigned-url`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        keyName: `${file[0].name}`,
      },
    };

    axios(config)
      .then((res) => {
        delete axios.defaults.headers.common["Authorization"];
        const configSecondPart = {
          method: "put",
          url: res.data.response.presignedUrl,
          headers: {
            Accept: `${file[0].type}`,
            "Content-Type": `${file[0].type}`,
          },
          data: file[0],
          cancelToken: cancelFile.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        };
        axios(configSecondPart)
          .then((res) => {
            delete axios.defaults.headers.common["Authorization"];
            const configThirdPart = {
              method: "POST",
              url: `${process.env.REACT_APP_BASE_URL}/upload-chat-icon`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
              },
              data: {
                keyName: file[0].name,
                projectCode: id,
              },
            };

            axios(configThirdPart)
              .then((res) => {})
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
            } else {
              console.log("AXIOS ERROR 2: ", err);
            }
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(`Request ${requestId} canceled`);
        } else {
          console.log("AXIOS ERROR 1: ", err);
        }
      });
  };

  const [currentFileImage, setCurrentFileImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setChatbotIcon(event.target.files);
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCurrentFileImage(imageUrl);
    } else {
      setCurrentFileImage(null);
    }
  };

  const [activePanel, setActivePanel] = useState(0);

  const handlePanelToggle = (panelNumber) => {
    setActivePanel((prevActivePanel) =>
      prevActivePanel === panelNumber ? null : panelNumber
    );
  };

  const isPanelActive = (panelNumber) => activePanel === panelNumber;

  return (
    <div className='row position-relative'>
      <>
        <div className='col-12 col-lg-5'>
          <div className='card'>
            <div className='card-body h-100 '>
              <div className='row'>
                <div className='custom-accordion' id='accordionAspettoChat'>
                  {/* Informazioni Base */}

                  <BasicInformationChat
                    handlePanelToggle={handlePanelToggle}
                    isPanelActive={isPanelActive}
                  ></BasicInformationChat>
                  <hr></hr>

                  {/* Stili della Chat */}

                  <StylesOfTheChat
                    currentFileImage={currentFileImage}
                    progetto={progetto}
                    AvatarPink={AvatarPink}
                    handleImageChange={handleImageChange}
                    handlePanelToggle={handlePanelToggle}
                    isPanelActive={isPanelActive}
                  ></StylesOfTheChat>
                  <hr></hr>

                  {/* Form di Contatto */}
                  <FormRequestContact
                    progetto={progetto}
                    handlePanelToggle={handlePanelToggle}
                    isPanelActive={isPanelActive}
                  ></FormRequestContact>
                  <hr></hr>

                  {/* Domande Predefinite */}
                  <PredefinedQueriesSection
                    progetto={progetto}
                    handlePanelToggle={handlePanelToggle}
                    isPanelActive={isPanelActive}
                  ></PredefinedQueriesSection>

                  {/* Bottone Aggiorna */}

                  <div className='col-12 d-flex align-items-center gap-2 justify-content-end my-4'>
                    <button
                      href=''
                      className='btn border-1 text-dark d-block d-lg-none'
                      onClick={() => setVisualizzaAnteprimaChat(true)}
                    >
                      Vedi Anteprima
                    </button>
                    <a
                      href=''
                      className='btn bg-green-second text-white'
                      onClick={(event) => AggiornaProgetto(event)}
                    >
                      Aggiorna
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <VisualizzaAnteprimaChat
          progetto={progetto}
          currentFileImage={currentFileImage}
          AvatarPink={AvatarPink}
        ></VisualizzaAnteprimaChat>
        <div className='col-7 d-none d-lg-block'>
          <ChatPreview
            progetto={progetto}
            currentFileImage={currentFileImage}
            AvatarPink={AvatarPink}
            activePanel={activePanel}
          ></ChatPreview>
        </div>
      </>
    </div>
  );
};

export default AspettoChat;
