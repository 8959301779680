import React, { useState, useEffect, useRef, useContext } from "react";
import { BiMessage as Messaggio } from "react-icons/bi";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams } from "react-router-dom";
import { StateContext } from "./StateContainer";
import { BsSearch as Cerca } from "react-icons/bs";
import Moment from "react-moment";

import "../App.css";
const StoricoConversazioni = ({ projectCode }) => {
  const { id } = useParams();
  const [chatConversation, setChatConversation] = useState([]);
  const [newConversation, setNewConversation] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const {
    setError,
    token,
    setConversationCode,
    conversationCode,
    filtraConversazioni,
    setFiltraConversazioni,
  } = useContext(StateContext);

  const LinkChatStorico = (code) => {
    setConversationCode(code);
    setSelectedConversation(code); // Imposta la conversazione selezionata
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/conversation/list`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        limit: 300,
        offset: "",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setError("");
          setChatConversation(response.data.response);
          // setConversationCode(response.data.response[0].code);
        }
      })
      .catch((err) => setError(err));
  }, [newConversation]);

  const risultatiFiltrati = chatConversation.filter((conversation) => {
    const lowerCaseFiltraConversazioni = filtraConversazioni.toLowerCase();

    if (filtraConversazioni === "") {
      return conversation;
    } else if (
      conversation.lastUserMessage
        .toLowerCase()
        .includes(lowerCaseFiltraConversazioni)
    ) {
      return conversation;
    }
  });

  let iframeChatbot = document.getElementById("iframe-chatbot");

  const EliminaConversationCode = (event) => {
    setNewConversation(true);
    event.preventDefault();
    setConversationCode("");
    Cookies.remove("conversationId-" + projectCode);
    if (iframeChatbot && conversationCode === "") {
      iframeChatbot.contentWindow.postMessage({ action: "reloadIframe" }, "*");
    }
  };


  
  


  return (
    <>
      <div className='card p-2' style={{ height: "100%", maxHeight: "800px", minHeight: "800px"}}>
        <div className='card-header bg-white border-0'>
          <button
            type='button'
            className='btn-close text-reset button-close-offcanvas-storico d-block d-lg-none'
            data-bs-dismiss='offcanvas'
            aria-label='Close'
          ></button>
          <h6 className='fw-bold pt-3 pb-2'>Storico conversazioni</h6>
          <div className='form d-flex align-items-center'>
            <p className='fa fa-search d-flex m-0'>
              <Cerca className='m-0' />
            </p>
            <input
              type='text'
              className='form-control form-input'
              placeholder='Cerca conversazione ...'
              value={filtraConversazioni}
              onChange={(event) => setFiltraConversazioni(event.target.value)}
            />
          </div>
        </div>
        <div
          className='card-body archivio-conversazioni'
          style={{ overflowY: "auto" }}
        >
          <div className=''>
            {/* <div className='d-flex align-items-center gap-2 position-relative pb-4 pt-2'>
              <Messaggio />
              <p className='m-0'>Avvia nuova conversazione</p>
              <a
                className='stretched-link btn'
                onClick={EliminaConversationCode}
              ></a>
            </div> */}
            <div>
              <h6 className='mt-2'>Conversazioni precedenti</h6>
            </div>

            {chatConversation.length === 0 ? (
              <div className='fs-6 py-3 text-secondary'>
                <i>Ancora nessuna conversazione</i>
              </div>
            ) : risultatiFiltrati.length === 0 ? (
              <div className='fs-6 py-3 text-secondary'>
                <i>Nessun risultato trovato</i>
              </div>
            ) : (
              risultatiFiltrati.map((conversation, index) => {
                return (
                  <div
                    className={`py-3 px-2 conversation_chat  ${
                      selectedConversation === conversation.code ? "bg-verde text-white" : ""
                    }`}
                    key={index}
                  >
                    <div className='mb-1'>
                      <p className='text-muted m-0' style={{ fontSize: "8pt" }}>
                        <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                          {conversation.created.date}
                        </Moment>
                      </p>
                    </div>
                    <div className='d-flex align-items-center' key={index}>
                      <div
                        className={`d-flex align-items-center gap-2 position-relative `}
                        style={{ width: "1000px" }}
                      >
                        <Messaggio />
                        <p className='m-0'>
                          {conversation.lastUserMessage.length > 30
                            ? conversation.lastUserMessage.substring(0, 30) + " ..."
                            : conversation.lastUserMessage}
                        </p>
                        <a
                          className='stretched-link btn'
                          onClick={() => LinkChatStorico(conversation.code)}
                        ></a>
                      </div>
                    </div>
                  </div>
                );
              })
              
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoricoConversazioni;
