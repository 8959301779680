import React, { useState, memo } from 'react';
import { Position, Handle, useReactFlow } from '@xyflow/react';

function TextNode({ id, data }) {
  console.log(data);
  return (
    <div className="border p-3 rounded shadow">
      <div>{data.label}</div>
      <div>
        <input style={{ display: 'block' }} />
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
}

export default memo(TextNode);
