import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const NavbarGeneralEdit = ({
  setNavbarGeneralChange,
  dettagliProgetto,
  navbarGeneralChange,
}) => {
  const { id } = useParams();

  return (
    <>
      <nav className="navbar d-flex align-items-center w-100 m-0 p-0">
        <div className="container-fluid m-0 p-0">
          <div>
            <div className="row d-flex align-items-center justify-content-start m-0 p-0 w-100">
              <div
                className="col-auto nav-item list-group-item navbar-modifica-item p-0 d-flex align-items-center justify-content-center px-3 w-auto"
                style={{ height: '60px' }}
              >
                <a
                  href="#configuraProgetto"
                  className={`nav-link text-capitalize fw-bold p-0 ${
                    navbarGeneralChange === '#configuraProgetto'
                      ? 'active-modifica'
                      : ''
                  }`}
                  value="#configuraProgetto"
                  onClick={(link) => {
                    setNavbarGeneralChange('#configuraProgetto');
                  }}
                >
                  Configura Assistente
                </a>
              </div>
              <div
                className="col-auto nav-item list-group-item navbar-modifica-item p-0 d-flex align-items-center justify-content-center px-3 w-auto"
                style={{ height: '60px' }}
              >
                <a
                  href="#aspettoChat"
                  className={`nav-link text-capitalize fw-bold p-0 ${
                    navbarGeneralChange === '#aspettoChat'
                      ? 'active-modifica'
                      : ''
                  }`}
                  value="#aspettoChat"
                  onClick={(link) => {
                    setNavbarGeneralChange('#aspettoChat');
                  }}
                >
                  Design e Aspetto
                </a>
              </div>
              <div
                className="col-auto nav-item list-group-item navbar-modifica-item p-0 d-flex align-items-center justify-content-center px-3 w-auto"
                style={{ height: '60px' }}
              >
                <a
                  href="#caricaDocumenti"
                  className={`nav-link text-capitalize fw-bold p-0 ${
                    navbarGeneralChange === '#caricaDocumenti'
                      ? 'active-modifica'
                      : ''
                  }`}
                  value="#caricaDocumenti"
                  onClick={(link) => {
                    setNavbarGeneralChange('#caricaDocumenti');
                  }}
                >
                  Caricamento Contenuti
                </a>
              </div>
              <div
                className="col-auto nav-item list-group-item navbar-modifica-item p-0 d-flex align-items-center justify-content-center px-3 w-auto"
                style={{ height: '60px' }}
              >
                <a
                  href="#includiInProgetto"
                  className={`nav-link text-capitalize fw-bold p-0 ${
                    navbarGeneralChange === '#includiInProgetto'
                      ? 'active-modifica'
                      : ''
                  }`}
                  value="#includiInProgetto"
                  onClick={(link) => {
                    setNavbarGeneralChange('#includiInProgetto');
                  }}
                >
                  Integrazione
                </a>
              </div>
              <div
                className="col-auto nav-item list-group-item navbar-modifica-item p-0 d-flex align-items-center justify-content-center px-3 w-auto"
                style={{ height: '60px' }}
              >
                <a
                  href="#storicoConversazioni"
                  className={`nav-link text-capitalize fw-bold p-0 ${
                    navbarGeneralChange === '#storicoConversazioni'
                      ? 'active-modifica'
                      : ''
                  }`}
                  value="#storicoConversazioni"
                  onClick={(link) => {
                    setNavbarGeneralChange('#storicoConversazioni');
                  }}
                >
                  Storico Conversazioni
                </a>
              </div>
            </div>
          </div>

          <div className="p-2 d-none d-md-block col-auto">
            {dettagliProgetto[0] &&
            dettagliProgetto[0].type === 'mysql-agent' ? (
              <a
                href="#agenteSQL"
                className={`btn bg-orange text-white fw-bold font-size-15`}
                value="#agenteSQL"
                onClick={(link) => {
                  setNavbarGeneralChange('#agenteSQL');
                }}
              >
                Prova l'assistente
              </a>
            ) : dettagliProgetto[0] &&
              dettagliProgetto[0].type === 'conversational' ? (
              <a
                href="#conversationalAssistant"
                className={`btn bg-orange text-white fw-bold font-size-15`}
                value="#conversationalAssistant"
                onClick={(link) => {
                  setNavbarGeneralChange('#conversationalAssistant');
                }}
              >
                Prova l'assistente
              </a>
            ) : (
              <a
                href="#qaAssistant"
                className={`btn bg-orange text-white fw-bold font-size-15`}
                value="#qaAssistant"
                onClick={(link) => {
                  setNavbarGeneralChange('#qaAssistant');
                }}
              >
                Prova l'assistente
              </a>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarGeneralEdit;
