import React, { useContext } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { StateContext } from "../StateContainer";
import ModalEliminaProgetto from "../Modals/ModalEliminaProgetto";
import OffcavasGestioneProgetto from "../TransitionPages/OffcavasGestioneProgetto";

const CardListaProgetti = ({ progetto, index }) => {
  const {
    setCodiceProgetto,
    setProjectTitle,
    setShowDeleteModal,
    codiceProgetto,
    projectTitle,
  } = useContext(StateContext);
  const modalElimina = document.getElementById("sa-warning");
  const bodyElimina = document.getElementById("bodyApp");

  const MostraModalElimina = (codice, title) => {
    setShowDeleteModal(true);
    setCodiceProgetto(codice);
    setProjectTitle(title);
  };

  return (
    <React.Fragment key={index}>
      <div className='card shadow-sm '>
        <div className='card-body py-4 '>
          {/* Nome Progetto */}
          <p className='text-uppercase font-size-15 fw-bold mb-2 '>Nome</p>
          <p className='mb-2'>{progetto.title}</p>
          <hr className='mt-0' />

          {/* Caratteri Totali */}

          <p className='text-uppercase font-size-15 fw-bold mb-2'>
            Caratteri Totali
          </p>
          <p className='mb-2'>{(progetto.charsCount || 0).toLocaleString()}</p>
          <hr className='mt-0' />

          {/* Caratteri Totali */}

          <p className='text-uppercase font-size-15 fw-bold mb-2'>
            Data Creazione
          </p>
          <p className='mb-2'>
            <Moment format='DD MMMM YYYY' locale='it'>
              {progetto.created.date}
            </Moment>
          </p>
          <hr className='mt-0' />

          {/* Modifica ed Elimina */}
          <div className='d-flex justify-content-between mt-4'>
            <Link
              className='btn bg-green-second text-white w-100 me-2'
              type='button'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasWithBackdrop'
              aria-controls='offcanvasWithBackdrop'
            >
              Modifica
            </Link>
            <Link
              className='btn bg-danger text-white w-100 ms-2'
              onClick={() => MostraModalElimina(progetto.code, progetto.title)}
              role='button'
              type='button'
              title='Elimina Progetto'
              data-bs-toggle='modal'
              data-bs-target='#sa-warning'
            >
              Elimina
            </Link>
          </div>

          {/* Prova Chatbot */}
          <div className='d-flex justify-content-between mt-3'>
            <Link
              className='btn bg-orange text-white w-100 rounded-pill'
              to={`/progetti/modifica-progetto/${progetto.code}/test-assistente`}
            >
              Parla con l'Assistente
            </Link>
          </div>
        </div>
      </div>
      <OffcavasGestioneProgetto progetto={progetto} />
      <ModalEliminaProgetto></ModalEliminaProgetto>
    </React.Fragment>
  );
};

export default CardListaProgetti;
